import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contacts = () => (
  <Layout>
    <Seo title="Контакты" />
    <div className="py-10 px-4 md:px-0">
      <h1 className="text-2xl font-semibold">Контакты</h1>
      <div className="flex flex-col space-y-8 py-8 font-medium text-lg">
        <div className="font-semibold  font-raleway">
          «Красильников, Фролов и партнеры»
        </div>
        <div className="text-gray-700">
          Телефоны:
          <br />
          +7 (843) 207-01-88,
          <br /> +7 (987) 290-07-27
        </div>
      </div>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <a
          href="https://yandex.ru/maps/org/krasilnikov_frolov_i_partnery/120377155729/?utm_medium=mapframe&utm_source=maps"
          style={{
            color: "#eee",
            fontSize: "12px",
            position: "absolute",
            top: "0px",
          }}
        >
          Красильников, Фролов и партнеры
        </a>
        <a
          href="https://yandex.ru/maps/43/kazan/category/disinfection_disinfestation_pest_removal/184108211/?utm_medium=mapframe&utm_source=maps"
          style={{
            color: "#eee",
            fontSize: "12px",
            position: "absolute",
            top: "14px",
          }}
        >
          Дезинфекция, дезинсекция, дератизация в Казани
        </a>
        <div>
          <iframe
            src="https://yandex.ru/map-widget/v1/-/CCUyBYEaDC"
            width="100%"
            height="600"
            title="d"
            frameborder="1"
            allowfullscreen="true"
            style={{ position: "relative" }}
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default Contacts
